import { withAuth0 } from '@auth0/auth0-react';
import { withTranslation } from 'react-i18next';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/TruckOrders';

export default withAuth0(withTranslation()(class TodaysTruckOrders extends CrudList {
  constructor(props) {
    super(props);
    this.state.tableOpen = true;
  }

  async componentDidMount() {
    this.loadOnMount();
  }

  getColumns = () => Columns(this);

  getListApi = () => 'contracts/tms_truck_orders';

  getListParams = () => ({ show_all: 'true', pickup_date_today: 'Y', sorted: [{ id: 'id', desc: false }] });

  getStorageName = () => 'otto_todays_truck_orders_list';

  getPageTitle = () => {
    const title = this.props.t('pageTitles.todaysTruckOrders');
    const loading = this.props.t('general.loadingLC');
    return `${title} (${this.state.loading ? loading : this.state.data.length})`;
  };

  getContent = () => this.contentCollapsible();

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;
}));
