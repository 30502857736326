const StorageToken = {};

StorageToken.get = () => {
  let tokenName = 'okta-user-info-storage';

  if (process.env.REACT_APP_TITLE_ENV !== 'production') {
    tokenName += `-${process.env.REACT_APP_TITLE_ENV}`;
  }
  return tokenName;
};

export default StorageToken;
