import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import { AddIcon, CrudList, Links } from '@westernmilling/eu_react_shared';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import Columns from 'helpers/columns/TruckOrders';
import UnshippedTruckOrders from 'components/Tables/UnshippedTruckOrders';
import TodaysTruckOrders from 'components/Tables/TodaysTruckOrders';

export default withAuth0(withTranslation()(class TruckOrderDashboardListContainer extends CrudList {
  async componentDidMount() {
    this.loadOnMount();
  }

  getColumns = () => Columns(this);

  getListApi = () => 'contracts/tms_truck_orders';

  getListParams = () => ({
    show_all: 'true',
    filtered: [{ id: 'status_in', value: ['Available', 'Price Hold', 'Credit Hold'] }],
    sorted: [{ id: 'id', desc: false }],
  });

  getStorageName = () => 'otto_contracts_unscheduled_truck_orders_list';

  getPageTitle = () => {
    const title = this.props.t('pageTitles.unscheduledTruckOrders');
    const loading = this.props.t('general.loadingLC');
    return `${title} (${this.state.loading ? loading : this.state.data.length})`;
  };

  getEditGroups = () => ['execution_admin'];

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  getContent = () => (
    <div>
      <Row>
        <Col md="8">
          <h2>
            {this.props.t('pageTitles.dashboard')}
          </h2>
        </Col>
        <Col md="4" className="text-right">
          {this.createButton()}
        </Col>
      </Row>
      <br />
      <br />
      {this.contentCollapsible()}
      <br />
      <hr />
      <UnshippedTruckOrders props={this.props} auth={this.props.auth} />
      <br />
      <hr />
      <TodaysTruckOrders props={this.props} auth={this.props.auth} />
    </div>
  );

  createButton = () => (
    <div>
      <AddIcon
        className="otto__margin_btm_30"
        onClick={() => { window.location = Links.baseUrl('tms', 'truck_orders'); }}
        addText={this.props.t('buttons.allTruckOrders')}
      />
    </div>
  );

  smallTitle = (caret = false) => (
    <Row>
      <Col md="8" className="text-left">
        { caret ? this.getTitle('table') : this.getTitle('page')}
      </Col>
    </Row>
  );
}));
