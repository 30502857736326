import { Api } from '@westernmilling/eu_react_shared';
import React, { Component } from 'react';
import {
  Button, Col, Nav, Navbar, NavLink, NavbarBrand, Row, UncontrolledAlert,
} from 'reactstrap';
import { withAuth0 } from '@auth0/auth0-react';
import { withTranslation } from 'react-i18next';

import StorageToken from 'helpers/StorageToken';
import Languages from 'components/Options/Languages';
import logo from 'images/elian_logo.png';

export default withAuth0(withTranslation()(class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };
  }

  getNavBarClass = () => {
    let css = '';
    if (this.props.app_env === 'production') {
      css = 'navbar-light bg-light box-shadow border-bottom align-items-center';
    } else {
      css = 'navbar-dark bg-dark align-items-center';
    }
    return css;
  }

  getTitleText = () => {
    let text = 'TMS';
    if (this.props.app_env !== 'production') {
      text += ` Non-Prod ( ${this.props.app_env} environment )`;
    }
    return text;
  }

  getRefreshMsg = () => {
    let msg = '';
    if (window.location.href.includes('expired=Y')) {
      msg = (
        <UncontrolledAlert color="info" className="text-center">
          This page was refreshed because your login expired.
        </UncontrolledAlert>
      );
    }
    return msg;
  }

  logout = async (e) => {
    e.preventDefault();
    window.localStorage.removeItem(StorageToken.get());
    this.props.auth0.logout({ logoutParams: { returnTo: process.env.REACT_APP_FULL_URL } });
  }

  toggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }))

  dropdowns = () => (
    <Col md="2" className="text-right">
      <Button color="link" className="otto__no_padding" onClick={this.logout}>
        {this.props.t('general.logout')}
        &nbsp;
        {this.props.auth0.user?.name}
      </Button>
      &nbsp;
      &nbsp;
      &nbsp;
      &nbsp;
      &nbsp;
      &nbsp;
      <Button color="link" className="otto__no_padding">
        {this.props.t('general.languageTitle')}
      </Button>
    </Col>
  )

  onChange = (e) => {
    localStorage.setItem('languageTranslation', e.target?.value?.value);
    window.location.reload(true);
  }

  clearApiHost = () => {
    Api.clearApiHost();
    window.location.reload(true);
  }

  render() {
    const ottoLink = `${process.env.REACT_APP_BASE_URL}/tms${process.env.REACT_APP_URL_ENV}/dashboard`;
    return (
      <div>
        <Row className={this.getNavBarClass()}>
          <Col md="1" className="text-right" style={{ paddingTop: '10px', paddingLeft: '25px' }}>
            <NavbarBrand href={ottoLink}>
              <img src={logo} height="60" alt="logo" />
            </NavbarBrand>
          </Col>
          <Col md="4">
            <NavbarBrand href={ottoLink}>{ this.getTitleText() }</NavbarBrand>
          </Col>
          <Col md="3">
            {Api.localStorageHost()
              ? (
                <span>
                  <small className="otto__margin_right" style={{ color: '#fff' }}>
                    Host:
                    {Api.localStorageHost()}
                  </small>
                  <Button color="secondary" size="sm" onClick={this.clearApiHost}>
                    clear
                  </Button>
                </span>
              ) : ''}
          </Col>
          {this.dropdowns()}

          <Col md="2" style={{ paddingTop: '15px' }}>
            <Languages
              labelColumns="0"
              fieldColumns="11"
              onChange={this.onChange}
              currentValue={localStorage.getItem('languageTranslation') || 'es'}
            />
          </Col>
        </Row>
        {this.getRefreshMsg()}
        <br />
      </div>
    );
  }
}));
