/* eslint-disable react/jsx-props-no-spreading */

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { HtmlHelpers } from '@westernmilling/eu_react_shared';

import Header from 'helpers/Header';
import Auth0ProviderWithRedirect from 'helpers/Auth0/Auth0ProviderWithRedirect';
import ProtectedRoute from 'helpers/Auth0/ProtectedRoute';

import TruckOrderDashboardListContainer from 'containers/TruckOrderDashboardListContainer';
import TruckOrderListContainer from 'containers/TruckOrderListContainer';
import TruckOrderViewContainer from 'containers/TruckOrderViewContainer';

const pageStyle = { padding: '0px 20px 0px 20px' };

export default class AppJs extends Component {
  constructor(props) {
    super(props);
    this.state = { userName: HtmlHelpers.getUser() };
  }

  authParams = (props) => ({ auth_type: 'auth0', ...props.auth0 });

  render() {
    return (
      <div className="App">
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
          <Auth0ProviderWithRedirect>
            <Header name={this.state.userName} userInfo={this.state.userInfo} app_env={process.env.REACT_APP_TITLE_ENV} />
            <div style={pageStyle}>
              <Switch>
                <ProtectedRoute
                  exact
                  path="/dashboard"
                  render={(props) => (
                    <TruckOrderDashboardListContainer {...props} auth={this.authParams(props)} />
                  )}
                />
                <ProtectedRoute
                  exact
                  path="/truck_orders/view/:uuid"
                  render={(props) => (
                    <TruckOrderViewContainer {...props} auth={this.authParams(props)} />
                  )}
                />
                <ProtectedRoute
                  exact
                  path="/truck_orders"
                  render={(props) => (
                    <TruckOrderListContainer {...props} auth={this.authParams(props)} />
                  )}
                />
                <ProtectedRoute
                  default
                  render={(props) => (
                    <TruckOrderDashboardListContainer {...props} auth={this.authParams(props)} />
                  )}
                />
              </Switch>
            </div>
          </Auth0ProviderWithRedirect>
        </BrowserRouter>
      </div>
    );
  }
}
