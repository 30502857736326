import { withTranslation } from 'react-i18next';
import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default withTranslation()(class Locations extends OptionsBaseApi {
  getApiName = () => 'entities/locations';

  getApiParams = () => this.props.apiParams || [];

  getDropdownLabelText = () => this.props.fieldLabel || this.props.t('fields.shipTo');

  getDropdownFieldName = () => this.props.fieldName || 'ship_to_location_uuid';

  getLabelFieldName = () => 'dropdown_display';
});
