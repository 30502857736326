import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { CrudForm, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

export default withAuth0(withTranslation()(withAlert()(class TruckOrderViewForm extends CrudForm {
  formType = () => this.props.t('pageTitles.truckOrder');

  getCrudType = () => 'contracts/tms_truck_orders';

  auditLogs = () => '';

  qtyShippedStr = () => `${this.getItem().quantity_shipped} ${this.getItem().unit_of_measure_symbol}`;

  quantityShipped = () => (
    HtmlHelpers.isEmpty(this.getItem().quantity_shipped) ? (
      this.getRow('quantity_shipped', this.props.t('fields.shippedQty'))
    ) : (this.getRowWithValue(this.props.t('fields.shippedQty'), this.qtyShippedStr()))
  );

  endorsee = () => (
    HtmlHelpers.isNotEmpty(this.getItem().endorsee_name) ? this.getRow('endorsee_name', this.props.t('fields.endorsee')) : ''
  );

  qtyOrderedStr = () => `${this.getItem().quantity_ordered} ${this.getItem().unit_of_measure_symbol}`;

  orderDetails = () => (
    <div>
      <h4>
        {this.props.t('pageTitles.orderDetails')}
      </h4>
      <Row>
        <Col md={4} sm={12}>
          {this.getRow('id', this.props.t('fields.orderId'))}
          {this.getRow('contract_id', this.props.t('fields.contract'))}
          {this.getRow('bill_to_name', this.props.t('fields.billTo'))}
          {this.endorsee()}
          {this.getRow('po_number', this.props.t('fields.customerPo'))}
        </Col>
        <Col md={4} sm={12}>
          {this.getRowWithValue(this.props.t('fields.orderQty'), this.qtyOrderedStr())}
          {this.getRow('quantity_shipped', this.props.t('fields.shippedQty'))}
          {this.getRow('gross_weight_at', this.props.t('fields.qtyScaled'))}
        </Col>
        <Col md={4} sm={12}>
          {this.getRowWithValue(this.props.t('fields.orderStatus'), this.props.t(`orderStatuses.${this.getItem().status}`))}
          {this.getRow('source_location', this.props.t('fields.source'))}
          {this.getRow('item_name', this.props.t('fields.item'))}
          {this.getRow('batch_number', this.props.t('fields.batchNumber'))}
        </Col>
      </Row>
    </div>
  );

  carrierAgency = () => (
    HtmlHelpers.isNotEmpty(this.getItem().carrier_agency_name)
      ? this.getRow('carrier_agency_name', 'Carrier Agency')
      : ''
  );

  shippingDetails = () => (
    <div>
      <hr />
      <h4>
        {this.props.t('pageTitles.shippingDetails')}
      </h4>
      <Row>
        <Col md={4} sm={12}>
          {this.getRow('pickup_date', this.props.t('fields.pickupDateScheduled'))}
          {this.getRow('time_slot_range', this.props.t('fields.timeSlot'))}
          {this.getRowWithValue(this.props.t('fields.shipTo'), <pre>{this.getItem().ship_to}</pre>)}
        </Col>
        <Col md={4} sm={12}>
          {this.carrierAgency()}
          {this.getRow('carrier_name', this.props.t('fields.carrier'))}
          {this.getRow('carrier_cif', this.props.t('fields.carrierCif'))}
          {this.getRow('seals', this.props.t('fields.seals'))}
        </Col>
        <Col md={4} sm={12}>
          {this.getRow('driver_name', this.props.t('fields.driver'))}
          {this.getRow('driver_id', this.props.t('fields.driverId'))}
          {this.getRow('truck_plate', this.props.t('fields.truckPlate'))}
          {this.getRow('trailer_plate', this.props.t('fields.trailerPlate'))}
        </Col>
      </Row>
    </div>
  );

  invoiceDetails = () => (this.getItem().is_bill_to === true ? (
    <div>
      <hr />
      <h4>
        {this.props.t('pageTitles.invoiceDetails')}
      </h4>
      <Row>
        <Col md={4} sm={12}>
          {this.getRow('invoice_number', this.props.t('fields.invoiceNumber'))}
        </Col>
        <Col md={4} sm={12}>
          {this.getRow('invoice_date', this.props.t('fields.invoiceDate'))}
        </Col>
        <Col md={4} sm={12}>
          {this.getRow('invoice_due_date', this.props.t('fields.invoiceDueDate'))}
        </Col>
      </Row>
    </div>
  ) : '');

  modalBodyRows = () => (
    <div>
      {this.orderDetails()}
      <br />
      {this.shippingDetails()}
      <br />
      {this.invoiceDetails()}
    </div>
  )
})));
