import React, { Component } from 'react';
import { Auth0Provider, LocalStorageCache } from '@auth0/auth0-react';
import { withRouter } from 'react-router-dom';

import StorageToken from 'helpers/StorageToken';

class Auth0ProviderWithRedirect extends Component {
  domain = process.env.REACT_APP_AUTH0_DOMAIN;

  clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  redirect = process.env.REACT_APP_FULL_URL;

  componentDidMount() {
    // Check and remove expired data from local storage
    const auth0RefreshToken = new LocalStorageCache();
    const keys = auth0RefreshToken.allKeys();
    const defaultKey = keys.find((key) => key.includes('default'));
    const data = JSON.parse(localStorage.getItem(defaultKey));

    if (data && (Date.now() > (data.expiresAt * 1000))) {
      localStorage.removeItem(StorageToken.get());
      keys.forEach((key) => {
        localStorage.removeItem(key);
      });
    }
  }

  onRedirectCallback = (appState) => {
    this.props.history.push('dashboard');
  };

  render() {
    return (
      <Auth0Provider
        domain={this.domain}
        clientId={this.clientId}
        redirectUri={this.redirect}
        onRedirectCallback={this.onRedirectCallback}
        audience={this.audience}
        useRefreshTokens
        cacheLocation="localstorage"
      >
        {this.props.children}
      </Auth0Provider>
    );
  }
}

export default withRouter(Auth0ProviderWithRedirect);
