import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import { CrudForm, HtmlHelpers, OptionsCountry } from '@westernmilling/eu_react_shared';

import Carriers from 'components/Options/Carriers';

export default withAuth0(withAlert()(class CarrierField extends CrudForm {
  constructor(props) {
    super(props);
    this.state.addNewCarrier = false;
  }

  openAddNewCarrier = () => this.setState({ addNewCarrier: true });

  formType = () => 'Carrier';

  onChange = (event) => this.props.onChange(event);

  getItem = () => this.props.item;

  getLabelColumns = () => 3;

  getFieldColumns = () => 7;

  options = () => (
    {
      carriers: {
        component: Carriers,
        field: 'carrier_entity_uuid',
        label: 'Carrier',
        currentValue: this.getItem().carrier_entity_uuid,
        disabled: this.state.addNewCarrier,
        buttonAfter: this.getButton('Add New Carrier', this.openAddNewCarrier),
      },
    }
  );

  carrierFields = () => (
    this.state.addNewCarrier ? (
      <div>
        {this.getTextFieldRow('new_carrier_name', 'Carrier Name', true, { value: this.getItem().new_carrier_name })}
        {this.getTextFieldRow('new_carrier_cif', 'Carrier CIF', true, { value: this.getItem().new_carrier_cif })}
      </div>
    ) : ''
  );

  getContents = () => (
    <div>
      {this.dropdownField('carriers')}
      {this.carrierFields()}
    </div>
  )
}));
