import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import { AddIcon, CrudList, Links } from '@westernmilling/eu_react_shared';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import Columns from 'helpers/columns/TruckOrders';
import ItemForm from 'components/Forms/TruckOrder';

export default withAuth0(withTranslation()(class TruckOrderListContainer extends CrudList {
  async componentDidMount() {
    this.loadOnMount();
  }

  getColumns = () => Columns(this);

  getListApi = () => 'contracts/tms_truck_orders';

  getStorageName = () => 'otto_contracts_truck_orders_list';

  getPageTitle = () => this.props.t('pageTitles.truckOrders');

  getFormTag = () => ItemForm;

  getEditGroups = () => ['execution_admin'];

  isEditAdmin = () => true;

  auditLogModelType = () => 'Contracts::TruckOrder';

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  loadOnMount = () => '';

  indexTable = () => this.paginationTable();

  createButton = () => (
    <div>
      <AddIcon
        className="otto__margin_btm_30"
        onClick={() => { window.location = Links.baseUrl('tms', 'dashboard'); }}
        addText={this.props.t('buttons.orderDashboard')}
      />
    </div>
  );

  smallTitle = (caret = false) => (
    <Row>
      <Col md="8" className="text-left">
        { caret ? this.getTitle('table') : this.getTitle('page')}
      </Col>
      <Col md="4" className="align-self-center text-right">
        { this.createButton() }
      </Col>
    </Row>
  );
}));
