import React from 'react';
import { ColumnButton, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: context.props.t('fields.orderId'),
    accessor: 'id',
    Cell: (row) => Links.getLink('tms', `truck_orders/view/${row.original.uuid}`, row.original.id),
    className: 'text-center',
    width: 100,
  },
  {
    id: 'bill_to_entity__name',
    Header: context.props.t('fields.customer'),
    accessor: 'bill_to_name',
    className: 'text-left',
  },
  {
    id: 'contract__contract_id',
    Header: context.props.t('fields.contract'),
    accessor: 'contract_with_schedule_number',
    className: 'text-left',
  },
  {
    id: 'agris_bin_desc',
    Header: context.props.t('fields.source'),
    accessor: 'source_location',
    className: 'text-left',
  },
  {
    id: 'carrier__name',
    Header: context.props.t('fields.carrier'),
    accessor: 'carrier_name',
    className: 'text-left',
  },
  {
    Header: context.props.t('fields.truckPlate'),
    accessor: 'truck_plate',
    className: 'text-center',
    width: 120,
  },
  {
    Header: context.props.t('fields.shipTo'),
    accessor: 'ship_to',
    Cell: (row) => (
      <pre>
        {row.original.ship_to}
      </pre>
    ),
    className: 'text-left',
  },
  {
    Header: context.props.t('fields.endorsee'),
    accessor: 'endorsee_name',
    className: 'text-left',
    show: false,
    csvShow: true,
  },
  {
    Header: context.props.t('fields.item'),
    accessor: 'item_name',
    className: 'text-left',
  },
  {
    id: 'quantity',
    Header: context.props.t('fields.quantity'),
    accessor: (d) => d.quantity_shipped || d.quantity_ordered,
    className: 'text-right',
    width: 150,
  },
  {
    id: 'unit_of_measure__symbol',
    Header: context.props.t('fields.uom'),
    accessor: 'unit_of_measure_symbol',
    className: 'text-center',
    width: 90,
  },
  {
    Header: context.props.t('fields.pickupDate'),
    accessor: 'pickup_date',
    className: 'text-center',
    width: 150,
  },
  {
    Header: context.props.t('fields.timeSlot'),
    accessor: 'time_slot_range',
    className: 'text-center',
    width: 150,
  },
  {
    Header: context.props.t('fields.status'),
    accessor: 'status',
    className: 'text-center',
    width: 150,
  },
  {
    Header: context.props.t('fields.customerPo'),
    accessor: 'po_number',
    className: 'text-center',
    width: 100,
    show: false,
    csvShow: true,
  },
  {
    Header: context.props.t('fields.finalUsage'),
    accessor: 'final_usage',
    className: 'text-left',
    width: 100,
    show: false,
    csvShow: true,
  },
];

export default Columns;
