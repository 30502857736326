import { withTranslation } from 'react-i18next';
import { OptionsBaseList } from '@westernmilling/eu_react_shared';

export default withTranslation()(class Languages extends OptionsBaseList {
  getDropdownLabelText = () => this.props.fieldLabel || '';

  getDropdownFieldName = () => this.props.fieldName || 'code';

  getStyles = () => ({ menu: (base) => ({ ...base, zIndex: 100 }) });

  getPlaceholder = () => this.props.t('general.select');

  getOptions = () => (
    [
      { label: this.props.t('general.languageEnglish'), value: 'en' },
      { label: this.props.t('general.languageSpanish'), value: 'es' },
    ]
  );
});
