import { withTranslation } from 'react-i18next';
import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default withTranslation()(class Carriers extends OptionsBaseApi {
  getApiName = () => 'entities/entities';

  getApiParams = () => ({ carriers: 'Y', show_all: 'true', sorted: JSON.stringify([{ id: 'name', desc: false }]) });

  getDropdownLabelText = () => this.props.fieldLabel || this.props.t('fields.carrier');

  getDropdownFieldName = () => this.props.fieldName || 'carrier_entity_uuid';
});
