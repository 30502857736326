import React from 'react';
import { withAlert } from 'react-alert';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { CrudView, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

import ItemForm from 'components/Forms/TruckOrder';
import ItemView from 'components/Forms/TruckOrderView';

export default withTranslation()(withAlert()(class TruckOrderViewContainer extends CrudView {
  constructor(props) {
    super(props);
    const last = props.location.pathname.lastIndexOf('/');
    const uuid = props.location.pathname.substring(last + 1);
    this.state.uuid = uuid;
    this.state.section = this.getSection();
  }

  editForm = () => ItemForm;

  editButton = () => '';

  isAdmin = () => true;

  getSection = () => (
    {
      type: 'contracts/tms_truck_orders',
      form: ItemView,
      title: this.props.t('pageTitles.truckOrderView'),
      listRoute: '../../dashboard',
      listLabel: this.props.t('buttons.orderDashboard'),
      edit_admins: [],
    }
  );

  editModal = () => {
    let contents = '';
    if (this.state.showModal) {
      const FormName = this.editForm();
      contents = (
        <FormName
          data={this.state.data}
          includes={this.state.includes}
          type="edit"
          editItem={0}
          showModal={this.state.showModal}
          closeModal={this.handleCloseModal}
          updateData={this.updateData}
          englishLanguageUuid={this.state.englishLanguageUuid}
          additionalFormProps={this.additionalFormProps()}
          auth={this.props.auth}
        />
      );
    }
    return contents;
  }

  additionalFormProps = () => ({ props: this.props })

  orderListButton = () => (
    <Button
      className="otto__margin_right"
      color="info"
      outline
      onClick={() => { window.location = Links.baseUrl('tms', 'truck_orders'); }}
    >
      {this.props.t('buttons.truckOrderList')}
    </Button>
  );

  editTruckButton = () => (!['Canceled', 'Shipped', 'Truck on Site', 'Invoiced'].includes(this.getItem().status) ? (
    <Button className="otto__margin_right" color="info" outline onClick={this.editEvent}>
      {this.props.t('buttons.edit')}
    </Button>
  ) : '');

  additionalButtonsAfterList = () => (
    <>
      {this.orderListButton()}
      {this.editTruckButton()}
    </>
  );

  updateData = () => {
    this.setState({ data: null });
    this.fetchItem();
  }
}));
