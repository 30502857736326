import React from 'react';
import { Route } from 'react-router-dom';
import { withAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

const ProtectedRoute = function protectedRoute(props) {
  return (
    <Route
      component={withAuth0(
        withAuthenticationRequired(props.render, {
          onRedirecting: () => <p>Loading...</p>,
        }),
      )}
      props={props}
    />
  );
};

export default ProtectedRoute;
