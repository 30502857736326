import { withAuth0 } from '@auth0/auth0-react';
import { withTranslation } from 'react-i18next';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/TruckOrders';

export default withAuth0(withTranslation()(class UnshippedTruckOrders extends CrudList {
  async componentDidMount() {
    this.loadOnMount();
  }

  getColumns = () => Columns(this);

  getListApi = () => 'contracts/tms_truck_orders';

  getListParams = () => ({
    show_all: 'true',
    filtered: [{ id: 'status_in', value: ['Scheduled'] }],
    sorted: [{ id: 'id', desc: false }],
  });

  getStorageName = () => 'otto_unshipped_truck_orders_list';

  getPageTitle = () => {
    const title = this.props.t('pageTitles.unshippedTruckOrders');
    const loading = this.props.t('general.loadingLC');
    return `${title} (${this.state.loading ? loading : this.state.data.length})`;
  };

  getContent = () => this.contentCollapsible();

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;
}));
